import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faF } from '@fortawesome/free-solid-svg-icons';

const Footer: React.FC = () => {
    return (
        <footer className="bg-black text-white py-8">
            <div className="container mx-auto px-4 md:px-8 lg:px-16">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                    {/* Logo and Social Media Icons */}
                    <div className="flex flex-col items-center justify-center md:items-start">
                        <img src="/icon.png" className="w-24 h-24 mb-4" alt="footer icon" />
                        <div className="flex space-x-4">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} className="w-6 h-6 hover:text-gray-400" />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} className="w-6 h-6 hover:text-gray-400" />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} className="w-6 h-6 hover:text-gray-400" />
                            </a>
                        </div>
                    </div>

                    <div></div>

                    {/* Navigation Section */}
                    <div className="flex flex-col items-center md:items-start">
                        <h2 className="text-lg font-light mb-4">Navigation</h2>
                        <ul className="space-y-2 text-center md:text-left">
                            <li>
                                <Link to="/" className="hover:text-gray-400">Home</Link>
                            </li>
                            <li>
                                <Link to="/about" className="hover:text-gray-400">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact" className="hover:text-gray-400">Contact Us</Link>
                            </li>
                        </ul>
                    </div>

                    {/* Address Section */}
                    <div className="flex flex-col items-center md:items-start">
                        <h2 className="text-lg font-light mb-4">Address</h2>
                        <address className="not-italic text-center md:text-left">
                            46 East Cost Road<br />
                            #10-01 East Gate<br />
                            Singapore 428766<br />
                            Phone: 9755 2163<br />
                            Email: <a href="mailto:info@example.com" className="hover:text-gray-400">info@example.com</a>
                        </address>
                    </div>
                </div>

                {/* Bottom Text */}
                <div className="mt-8 text-center border-t border-gray-700 pt-4">
                    <p>&copy; 2024 SunriseCap68. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
