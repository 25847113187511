import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useLanguage, switchLanguageWithMiddleware } from '../context/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Navbar: React.FC = () => {
    const location = useLocation();
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const isActive = (path: string) => location.pathname === path;

    const toggleLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <nav className="bg-black px-4 py-4 shadow-md roboto-thin sticky top-0 z-50">
            <div className="container mx-auto flex justify-between items-center md:px-24">
                <img src="/icon.png" className="w-12 h-12" alt="Sunrise Capital Icon" />

                {/* Hamburger icon for mobile */}
                <div className="md:hidden">
                    <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
                        <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} className="h-6 w-6" />
                    </button>
                </div>

                {/* Desktop Menu */}
                <ul className="hidden md:flex space-x-6 text-lg">
                    <li className={`cursor-pointer hover:text-gray-300 text-white ${isActive('/') ? 'roboto-medium border-b-2 border-yellow-400' : ''}`}>
                        <Link to="/">Home</Link>
                    </li>
                    <li className={`cursor-pointer hover:text-gray-300 text-white ${isActive('/about') ? 'roboto-medium border-b-2 border-yellow-400' : ''}`}>
                        <Link to="/about">About Us</Link>
                    </li>
                    <li className={`cursor-pointer hover:text-gray-300 text-white ${isActive('/contact') ? 'roboto-medium border-b-2 border-yellow-400' : ''}`}>
                        <Link to="/contact">Contact Us</Link>
                    </li>
                    {/* Language Switcher */}
                    <li className="text-white hover:text-gray-300 cursor-pointer flex items-center w-12" onClick={() => toggleLanguage(currentLanguage === 'id' ? 'en' : 'id')}>
                        {currentLanguage.toUpperCase()}
                        <img
                            src={`/${currentLanguage.toLowerCase()}.jpg`}
                            alt={currentLanguage === 'id' ? 'Indonesian Flag' : 'English Flag'}
                            className="ml-2 w-6 h-4 rounded-sm"
                        />
                    </li>
                </ul>

                {/* Mobile Menu with Animation */}
                <div
                    className={`md:hidden absolute top-16 left-0 w-full bg-black p-4 text-lg z-10 overflow-hidden transition-max-height duration-300 ease-in-out transform 
                        ${isMobileMenuOpen ? 'max-h-screen' : 'max-h-0'}`}
                >
                    <ul className="space-y-4 mt-6 text-right">
                        <li className={`cursor-pointer hover:text-gray-300 text-white ${isActive('/') ? 'roboto-medium border-b-2 border-yellow-400' : ''}`}>
                            <Link to="/" onClick={toggleMobileMenu}>Home</Link>
                        </li>
                        <li className={`cursor-pointer hover:text-gray-300 text-white ${isActive('/about') ? 'roboto-medium border-b-2 border-yellow-400' : ''}`}>
                            <Link to="/about" onClick={toggleMobileMenu}>About Us</Link>
                        </li>
                        <li className={`cursor-pointer hover:text-gray-300 text-white ${isActive('/contact') ? 'roboto-medium border-b-2 border-yellow-400' : ''}`}>
                            <Link to="/contact" onClick={toggleMobileMenu}>Contact Us</Link>
                        </li>
                        {/* Language Switcher in Mobile */}
                        <li className="text-white hover:text-gray-300 cursor-pointer flex items-center justify-end" onClick={() => toggleLanguage(currentLanguage === 'id' ? 'en' : 'id')}>
                            {currentLanguage.toUpperCase()}
                            <img
                                src={`/${currentLanguage.toLowerCase()}.jpg`}
                                alt={currentLanguage === 'id' ? 'Indonesian Flag' : 'English Flag'}
                                className="ml-2 w-6 h-4 rounded-sm"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
