import React, { createContext, useContext, useReducer, ReactNode } from 'react';

type Language = 'ID' | 'EN';

interface LanguageState {
    language: Language;
}

interface LanguageAction {
    type: 'SWITCH_LANGUAGE';
    payload: Language;
}

const initialState: LanguageState = {
    language: 'ID',
};

const languageReducer = (state: LanguageState, action: LanguageAction): LanguageState => {
    switch (action.type) {
        case 'SWITCH_LANGUAGE':
            return { language: action.payload };
        default:
            return state;
    }
};

const LanguageContext = createContext<{
    state: LanguageState;
    dispatch: React.Dispatch<LanguageAction>;
}>({
    state: initialState,
    dispatch: () => null,
});

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(languageReducer, initialState);
    return (
        <LanguageContext.Provider value={{ state, dispatch }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => useContext(LanguageContext);

export const switchLanguageWithMiddleware = (
    dispatch: React.Dispatch<LanguageAction>,
    language: Language
) => {
    // console.log(`Switching language to: ${language}`);
    setTimeout(() => {
        dispatch({ type: 'SWITCH_LANGUAGE', payload: language });
    }, 100);
};
