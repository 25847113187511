import React, { useState } from 'react';

interface ContactFormProps {
    schema: 'dark' | 'light'; // Define color schemes
}

const ContactForm: React.FC<ContactFormProps> = ({ schema }) => {
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to an API)
        console.log('Form submitted:', formData);
    };

    return (
        <section className={`my-12 ${schema === 'dark' ? 'bg-teal-800' : 'bg-slate-100'}`}>
            <div className={`max-w-3xl mx-auto px-8 md:px-0 ${schema === 'dark' ? 'text-white' : 'text-black'}`}>
                <h2 className="text-2xl font-bold">Let's Get In Touch</h2>
                <hr className="w-12 mb-6 border-0 h-1 rounded-full bg-amber-200" />

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium mb-2">Email Address</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`w-full p-2 border rounded-md ${schema === 'dark' ? 'bg-gray-800 border-gray-600' : 'bg-white border-gray-300'}`}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="subject" className="block text-sm font-medium mb-2">Subject</label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            className={`w-full p-2 border rounded-md ${schema === 'dark' ? 'bg-gray-800 border-gray-600' : 'bg-white border-gray-300'}`}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-sm font-medium mb-2">How can we help you?</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows={4}
                            className={`w-full p-2 border rounded-md resize-none ${schema === 'dark' ? 'bg-gray-800 border-gray-600' : 'bg-white border-gray-300'}`}
                            required
                        />
                    </div>
                    <div className='flex justify-end'>
                        <button
                            type="submit"
                            className={`bg-emerald-300 hover:bg-emerald-400 text-black font-bold py-2 px-10 rounded-lg shadow-lg`}
                        >
                            SEND
                        </button>
                    </div>

                </form>
            </div>
        </section>
    );
};

export default ContactForm;
