import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

import enTranslation from './locales/en/translation.json';
import idTranslation from './locales/id/translation.json';

i18n
    .use(HttpApi) // Load translation using http backend
    .use(initReactI18next) // Bind react-i18next to React
    .init({
        fallbackLng: 'en', // Default language
        supportedLngs: ['en', 'id'], // Supported languages
        resources: {
            en: {
                translation: enTranslation,
            },
            id: {
                translation: idTranslation,
            },
        },
        interpolation: {
            escapeValue: false, // React already escapes values
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json', // Path to translation files
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
            caches: ['cookie'],
        },
    });

export default i18n;
