import React from 'react';
import { useTranslation } from 'react-i18next';

interface AboutProps {
    schema: string;
}

const AboutUs: React.FC<AboutProps> = ({ schema }) => {
    const { t } = useTranslation();

    return (
        <section className="bg-gray-100 py-16">
            <div className={`max-w-3xl mx-auto px-4 flex flex-col ${schema === '2' ? '' : 'md:flex-row-reverse'} items-center`}>
                {/* Right Side - Text */}
                <div className={`w-full text-gray-800 px-4 md:px-8 ${schema === '2' ? 'mb-8 md:mb-4' : 'md:w-1/2'}`}>
                    <h2 className="text-2xl font-bold">About Us</h2>
                    <hr className="w-12 mb-6 border-0 h-1 rounded-full bg-amber-200" />
                    <p className="text-lg mb-6">
                        {t('aboutUs1')}
                    </p>
                    <p className="text-lg">
                        {t('aboutUs2')}
                    </p>
                </div>

                {/* Left Side - Images */}
                <div className={`w-full ${schema === '2' ? 'flex justify-center px-8' : 'h-96 md:w-1/2 mt-4'}`}>
                    {schema === '1' ? (
                        <div className="grid grid-rows-3 gap-4">
                            <div className="row-span-1">
                                {/* Top Image */}
                                <img
                                    src="/img/1.jpeg"
                                    alt="Image of a Trading Ship"
                                    className="w-full h-72 object-cover rounded-lg"
                                />
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    {/* Bottom Left Image */}
                                    <img
                                        src="/img/2.jpg"
                                        alt="Image of a factory plant"
                                        className="w-full h-70 object-cover rounded-lg"
                                    />
                                </div>
                                <div>
                                    {/* Bottom Right Image */}
                                    <img
                                        src="/img/3.jpg"
                                        alt="Image of a factory"
                                        className="w-full h-70 object-cover rounded-lg"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mt-8">
                            <div>
                                <img
                                    src="/img/3.jpg"
                                    alt="Image of a factory"
                                    className="w-38 object-cover rounded-lg"
                                />
                            </div>
                            <div>
                                <img
                                    src="/img/4.jpg"
                                    alt="Image of a factory plant"
                                    className="w-38 object-cover rounded-lg"
                                />
                            </div>
                            <div>
                                <img
                                    src="/img/2.jpg"
                                    alt="Image of a factory plant"
                                    className="w-38 object-cover rounded-lg"
                                />
                            </div>
                            <div>
                                <img
                                    src="/img/1.jpeg"
                                    alt="Image of a Trading Ship"
                                    className="w-38 object-cover rounded-lg"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AboutUs;