import React from 'react';
import FAQBase from '../components/FAQ';
import ContactForm from '../components/ContactForm';

const Contact: React.FC = () => {
    return (
        <div className="overflow-x-hidden">
            <FAQBase
                schema="dark"
            />
            <ContactForm schema="light" />
        </div>
    );
}

export default Contact;
