import React from 'react';

interface HeroProps {
    backgroundImage: string;
    title: string;
    subtitle?: string;
    buttonText?: string;
    titleColor?: string;
    onButtonClick?: () => void;
}

const Hero: React.FC<HeroProps> = ({ backgroundImage, title, titleColor, subtitle, buttonText, onButtonClick }) => {
    return (
        <div
            className="relative h-[500px] flex items-center justify-start text-start bg-cover bg-center px-4 md:px-48"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="absolute inset-0 bg-black opacity-75"></div>
            <div className="relative text-white">
                {title && (
                    <>
                        <h1 className="text-2xl md:text-3xl font-bold mb-4">{title}</h1>
                        <hr className={`w-12 mb-4 md:mb-8 border-0 h-1 rounded-full ${titleColor}`} />
                    </>
                )}
                {subtitle && (
                    <p className="text-2xl md:text-3xl font-light mb-6 md:mb-8 w-full md:w-2/3">
                        {subtitle}
                    </p>
                )}
                {buttonText && (
                    <button
                        onClick={onButtonClick}
                        className="bg-amber-200 hover:bg-amber-400 text-black font-bold py-2 px-4 md:py-3 md:px-6 rounded-lg shadow-lg flex items-center"
                    >
                        {buttonText}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="w-4 h-4 md:w-5 md:h-5 inline-block ml-2"
                        >
                            <path d="M5 12h14m-7-7l7 7-7 7" />
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
};

export default Hero;
