import React from 'react';
import Slider from "react-slick";

interface Sponsor {
    image: string;
    altText: string;
}

const sponsors: Sponsor[] = [
    { image: '/logo/athena.png', altText: 'Athena Corporation' },
    { image: '/logo/food-exim.png', altText: 'Food Exim' },
];

interface SponsorProps {
    schema: 'dark' | 'light'; // Define color schemes
}

const Sponsors: React.FC<SponsorProps> = ({ schema }) => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    return (
        <section className={`${schema === 'light' ? 'bg-slate-100' : 'bg-teal-800 text-white'} py-12`}>
            <div className="max-w-3xl mx-auto px-4">
                <h2 className="text-2xl font-bold">Our Partners</h2>
                <hr className="w-12 mb-6 border-0 h-1 rounded-full bg-amber-200" />

                {/* Slick Carousel for Sponsors */}
                <Slider {...settings}>
                    {sponsors.map((sponsor, index) => (
                        <div key={index} className="flex justify-center items-center p-4">
                            <img
                                src={sponsor.image}
                                alt={sponsor.altText}
                                className="h-auto w-52 object-contain"
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default Sponsors;
