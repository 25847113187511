import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface FAQItem {
    question: string;
    answer: string;
}

interface FAQProps {
    schema: 'dark' | 'light';
}

const FAQBase: React.FC<FAQProps> = ({ schema }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const { t } = useTranslation();

    const faqs: FAQItem[] = [
        {
            question: `${t('question1')}`,
            answer: `${t('answer1')}`,
        },
        {
            question: `${t('question2')}`,
            answer: `${t('answer2')}`,
        },
        {
            question: `${t('question3')}`,
            answer: `${t('answer3')}`,
        },
        {
            question: `${t('question4')}`,
            answer: `${t('answer4')}`,
        },
    ];

    const handleToggle = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className={`${schema === 'dark' ? 'bg-teal-800' : 'bg-slate-100'} h-[450px] md:h-[400px] px-8 md:px-0`}>
            <div className={`max-w-3xl mx-auto py-8`}>
                <div className="flex flex-col items-center">
                    <h2 className={`text-2xl font-bold ${schema === 'dark' ? 'text-white' : 'text-black'}`}>Frequently Asked Questions</h2>
                    <hr className={`w-12 mb-4 border-0 h-1 rounded-full bg-amber-200`} />
                </div>
                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="rounded-lg overflow-hidden transition-all duration-300">
                            <button
                                className={`w-full py-2 text-left focus:outline-none flex items-center ${schema === 'dark' ? 'text-white' : 'text-teal-800'}`}
                                onClick={() => handleToggle(index)}
                            >
                                <FontAwesomeIcon
                                    icon={faCaretDown}
                                    className={`h-6 mr-2 transition-transform duration-500 ${activeIndex === index ? 'rotate-180' : ''}`}
                                    style={{ color: "#8AB58F" }}
                                />
                                <span className="text-xl font-medium">{faq.question}</span>
                            </button>
                            <div
                                className={`px-6 transition-max-height duration-500 ease-in-out overflow-hidden ${activeIndex === index ? 'max-h-screen' : 'max-h-0'}`}
                            >
                                <p className={`roboto-light ${schema === 'dark' ? 'text-white' : 'text-black'}`}>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQBase;
