import React from 'react';
import Hero from '../components/Hero';
import MeetTheTeam from '../components/MeetTheTeam';
import Sponsors from '../components/Sponsors';
import AboutUs from '../components/About';
import { useTranslation } from 'react-i18next';

const About: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="overflow-x-hidden">
            <Hero
                backgroundImage="/bg/1.jpeg"
                title="About Us"
                subtitle={t('aboutUsHero')}
                buttonText=""
                titleColor="bg-green-200"
            />

            <AboutUs schema="2" />

            <Sponsors schema="dark" />

            {/* <MeetTheTeam schema="light" /> */}
        </div>
    );
}

export default About;
